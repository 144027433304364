<script setup lang="ts">
import navigationItems from '@/navigation'
import { onMounted, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useAbility } from '@casl/vue'
import { useOrdersStore } from '@/stores/orders'
import { useDisplay } from 'vuetify'

const { smAndDown } = useDisplay()
const ordersStore = useOrdersStore()
const baseUrl = import.meta.env.VITE_SERVER_API_URL
const { can } = useAbility()
const filteredNavigationItems = navigationItems.filter((item: any) => {
  if (!can('read', item.ability)) return false

  if (item.subItems && item.subItems.length > 0) {
    item.subItems = item.subItems.filter((subItem: any) => can('read', subItem.ability))
  }

  return true
})

const { user, setUser } = useUserStore()
const { remove } = useCookies(['token'])
const router = useRouter()
const rail = ref(false)
const selectedNavItem = ref()

const logout = () => {
  remove('token')
  localStorage.removeItem('userAbilities')
  setUser(null)
  router.push('/login')
}

function resetPassword() {
  window.open(baseUrl + '/my-account/edit-account/?login=true&type=forgot-password')
}

function editProfile() {
  window.open(baseUrl + '/my-account/edit-account/?login=true')
}
const route = useRoute()
const isInSubItems = (subItems: any) => {
  return subItems.some((subItem: any) => subItem.to === route.path)
}

// Handle the click on items with sub-items
const handleNavItemClick = (navigationItem: any) => {
  if (navigationItem.subItems && navigationItem.subItems.length > 0) {
    if (!isInSubItems(navigationItem.subItems)) {
      // If not in sub-items' routes, navigate to the main item route
      router.push(navigationItem.to)
    } else {
      // If already in one of the sub-items' routes, toggle the sub-items visibility
      selectedNavItem.value = selectedNavItem.value === navigationItem.id ? null : navigationItem.id
    }
  } else {
    // For items without sub-items, navigate normally
    router.push(navigationItem.to)
  }
}

function setRailValue() {
  localStorage.setItem('railValue', JSON.stringify(rail.value))
}

watch(rail, () => {
  setRailValue()
})

onMounted(() => {
  if (localStorage.getItem('railValue')) {
    rail.value = JSON.parse(localStorage.getItem('railValue') || 'false')
  }
  if (smAndDown.value) {
    rail.value = false
  }
})
</script>

<template>
  <v-navigation-drawer
    :rail="rail"
    mobile-breakpoint="md"
    style="position: fixed; min-width: 60px"
    width="250"
  >
    <v-list>
      <v-list-item :title="user?.displayName" :subtitle="user?.email" style="text-align: center">
        <template #prepend>
          <v-btn
            variant="text"
            class="px-0 d-flex justify-start"
            icon="mdi-chevron-left"
            v-if="rail && !smAndDown"
            @click.stop="rail = !rail"
          >
          </v-btn>
          <v-row>
            <v-col>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <span
                    class="mdi mdi-dots-vertical"
                    style="font-size: x-large"
                    v-bind="props"
                  ></span>
                </template>
                <v-list>
                  <v-list-item
                    title="تعديل الحساب"
                    prepend-icon="mdi-store-edit"
                    active-class="active-nav"
                    @click="editProfile"
                  ></v-list-item>
                  <v-list-item
                    title="تعديل كلمه السر"
                    prepend-icon="mdi mdi-lock-reset"
                    active-class="active-nav"
                    @click="resetPassword"
                  ></v-list-item>
                  <v-list-item
                    title=" تسجيل خروج "
                    prepend-icon="mdi-logout-variant"
                    active-class="active-nav"
                    @click.prevent="logout"
                  ></v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
        <template v-slot:append>
          <v-btn
            variant="text"
            v-if="rail && !smAndDown"
            icon="mdi-chevron-left"
            @click.stop="rail = !rail"
          ></v-btn>
          <v-btn
            variant="text"
            v-if="!rail && !smAndDown"
            icon="mdi-chevron-right"
            @click.stop="rail = !rail"
          ></v-btn>
        </template>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="mt-1">
      <div
        v-for="navigationItem in filteredNavigationItems"
        :key="navigationItem.id"
        :data-cy="`navbar-${navigationItem.key}`"
        @click="selectedNavItem = navigationItem.id"
      >
        <div v-if="navigationItem?.subItems?.length ? navigationItem.subItems.length > 0 : false">
          <v-list-group>
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                base-color="black"
                @click="handleNavItemClick(navigationItem)"
                :title="navigationItem.title"
                class="nav-item"
                :active-class="!rail ? 'active-nav-main' : 'active-nav'"
                :prepend-icon="navigationItem.icon.icon"
                style="min-height: 42px !important"
              >
                <v-badge
                  color="success"
                  dot
                  v-if="ordersStore.showBadge && navigationItem.key == 'orders'"
                  style="position: absolute; top: 0px; right: 30px"
                />
              </v-list-item>
            </template>
            <div v-if="!rail" style="padding-right: 16px">
              <div style="border-right: 2px solid #d9e1e5; padding-right: 5px">
                <v-list-item
                  class="nav-item"
                  v-for="item in navigationItem?.subItems"
                  :key="item.id"
                  :title="item.title"
                  :to="item.to"
                  :prepend-icon="item.icon.icon"
                  style="padding-right: 16px !important; min-height: 42px !important"
                  active-class="active-nav"
                >
                  <v-badge
                    v-if="ordersStore.showBadge && item.key == 'newOrders'"
                    color="success"
                    dot
                    style="position: absolute; top: 0px; right: 30px"
                  />
                </v-list-item>
              </div>
            </div>
          </v-list-group>
          <div v-if="rail" class="container" @click="rail = false">
            <div class="vertical-line"></div>
            <div v-if="selectedNavItem == navigationItem.id" class="point"></div>
          </div>
        </div>
        <v-list-item
          v-else
          class="nav-item"
          :title="navigationItem.title"
          :to="navigationItem.to"
          active-class="active-nav"
          style="min-height: 42px !important"
          :prepend-icon="navigationItem.icon.icon"
        ></v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<style>
.flex {
  display: flex;
  align-items: center;
}
.container {
  position: relative;
  width: 56px;
  height: 85px;
}

.v-badge--dot .v-badge__badge {
  border-radius: 50px !important;
  height: 15px !important;
  min-width: 0;
  padding: 0;
  width: 15px !important;
}
.vertical-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #333;
  content: '';
}
.point {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
}
@media screen and (min-width: 700) {
}
</style>
