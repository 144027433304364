import type { UserAbility } from '@/plugins/casl/AppAbility'

export const lightExcludeAbilities: UserAbility[] = [
  // Reviews
  {
    action: 'read',
    subject: 'Reviews'
  },
  {
    action: 'create',
    subject: 'Reviews'
  },
  {
    action: 'update',
    subject: 'Reviews'
  },
  {
    action: 'delete',
    subject: 'Reviews'
  },

  // Brands
  {
    action: 'read',
    subject: 'Brands'
  },
  {
    action: 'create',
    subject: 'Brands'
  },
  {
    action: 'update',
    subject: 'Brands'
  },
  {
    action: 'delete',
    subject: 'Brands'
  },
  // customers
  {
    action: 'read',
    subject: 'Customers'
  },
  {
    action: 'create',
    subject: 'Customers'
  },
  {
    action: 'update',
    subject: 'Customers'
  },
  {
    action: 'delete',
    subject: 'Customers'
  },
  // orders
  {
    action: 'read',
    subject: 'Orders'
  },
  {
    action: 'create',
    subject: 'Orders'
  },
  {
    action: 'update',
    subject: 'Orders'
  },
  {
    action: 'delete',
    subject: 'Orders'
  },
  // coupons
  {
    action: 'read',
    subject: 'Coupons'
  },
  // reports
  {
    action: 'read',
    subject: 'Reports'
  },

  // shipping
  {
    action: 'read',
    subject: 'Shipping'
  },
  // size charts
  {
    action: 'read',
    subject: 'SizeChart'
  },
  // trash
  {
    action: 'read',
    subject: 'Trash'
  },
  // builder
  {
    action: 'read',
    subject: 'Builder'
  },
  // birthday
  {
    action: 'read',
    subject: 'Birthdays'
  },
  // settings
  {
    action: 'update',
    subject: 'Settings'
  }
]
