import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import { adminAbilities } from '@/abilities/admin'
import { shopMangerAbilities } from '@/abilities/shopManger'
import { shopWorkerAbilities } from '@/abilities/shopWorker'
import { ordersWorkerAbilities } from '@/abilities/ordersWorker'
import { analyticsViewerAbilities } from '@/abilities/analyticsViewer'
import { lightExcludeAbilities } from '@/abilities/plans/lightExcludeAbilities'
import { mainExcludeAbilities } from '@/abilities/plans/mainExcludeAbilities'
import type { UserAbility } from '@/plugins/casl/AppAbility'

type User = {
  id: string
  email: string
  nicename: string
  firstName: string
  role: string
  lastName: string
  displayName: string
  token: string
  plan: string
}

interface UserState {
  user: Ref<User | null>
}

const roleAbilitiesMapper: Record<string, UserAbility[]> = {
  treesal_shop_manager: shopMangerAbilities,
  administrator: adminAbilities,
  orders_worker: ordersWorkerAbilities,
  shop_worker: shopWorkerAbilities,
  analytics_viewer: analyticsViewerAbilities,
  default: shopWorkerAbilities // Default abilities if role is unrecognized
}

const planAbilitiesMapper: Record<string, UserAbility[]> = {
  light: lightExcludeAbilities,
  main: mainExcludeAbilities
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: ref(null)
  }),
  actions: {
    getRoleAbilities(userRole: string): UserAbility[] {
      return roleAbilitiesMapper[userRole] || roleAbilitiesMapper.default
    },

    getPlanAbilities(userPlan: string): UserAbility[] {
      return planAbilitiesMapper[userPlan] || []
    },

    excludeAbilities(roleAbilities: UserAbility[], planAbilities: UserAbility[]): UserAbility[] {
      return roleAbilities.filter(
        (roleAbility) =>
          !planAbilities.some(
            (planAbility) =>
              roleAbility.action === planAbility.action &&
              roleAbility.subject === planAbility.subject
          )
      )
    },

    setUser(user: User | null) {
      this.user = user
      if (user?.role) {
        const roleAbilities = this.getRoleAbilities(user.role)
        const planAbilities = this.getPlanAbilities(user.plan || 'main')
        console.log(planAbilities)
        const commonAbilities = this.excludeAbilities(roleAbilities, planAbilities)
        localStorage.setItem('userAbilities', JSON.stringify(commonAbilities))

        return {
          success: true,
          abilities: commonAbilities
        }
      }
      return {
        success: false,
        abilities: null
      }
    }
  },
  persist: true
})
